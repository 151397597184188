import {Image} from '@shopify/hydrogen';
import type {
  Product,
  ProductVariant,
} from '@shopify/hydrogen/storefront-api-types';
import clsx from 'clsx';

import {Link} from '~/components/Link';
import ProductTile from '~/components/product/Tile';
import {useGid} from '~/lib/utils';

type Props = {
  gid: string;
  variantGid: string;
};

export default function ProductHero({gid, variantGid}: Props) {
  const storefrontProduct = useGid<Product>(gid);
  const firstVariant =
    useGid<ProductVariant>(variantGid) ??
    storefrontProduct?.variants.nodes.find(
      (variant) => variant.id == variantGid,
    ) ??
    storefrontProduct?.variants.nodes[0];

  if (!(storefrontProduct && firstVariant)) {
    return null;
  }

  return (
    <>
      <Link to={`/products/${storefrontProduct.handle}`}>
        {firstVariant.image && (
          <div className="heroImage">
            <Image
              className="absolute h-full w-full transform bg-cover bg-center object-cover object-center"
              data={firstVariant.image}
              sizes="100vw"
            />
          </div>
        )}
        <div
          className={clsx('invisible absolute bottom-4 right-4', 'md:visible')}
        >
          <ProductTile storefrontProduct={storefrontProduct} />
        </div>
      </Link>
    </>
  );
}
